.category-list{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}
.category-list-h2{
    font-size: 27px;
    text-align: center;
    width: 70%;
    margin: auto;
}
.list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px;
    margin: 50px 160px;
}
.list-img{
    width: 300px;
    height: 300px;
    transition: all 0.2s ease-in-out;
    &:hover{
        transform: scale(1.2);
    }
}
@media screen and (max-width: 1024px){
    .list{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px;
        margin: 100px 70px;
    }
    .list-img{
        width: 280px;
        height: 300px;
    } 
}
@media screen and (max-width: 768px){
    .list{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px;
        margin: 100px 70px;
    }
    .list-img{
        width: 280px;
        height: 300px;
    } 
}
@media screen and (max-width: 412px){
    .list{
        display: grid;
        grid-template-columns: 1fr;
        gap: 70px;
        margin: 60px;
    }
    .list-img{
        width: 300px;
        height: 300px;
    } 
}