#nav {
    display: flex;
    padding: 35px 50px;
    align-items: center;
    background-color: white;
    justify-content: space-between;
}
.nav-left{
    display: flex;
    align-items: center;
    position: relative;
}
.nav-left-img {
    position: absolute;
    left: 48px;
    inline-size: 55px;
    block-size: 55px;
    object-fit: cover;
    border-radius: 50%;
    opacity: 0.8;
    z-index: 10;
}
.nav-h3{
    font-size: 30px;
    font-weight: 800;
    z-index: 100;
}
.nav-right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.nav-ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.nav-li {
    list-style: none;
    border: 1px solid black;
    border-radius: 30px;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease-in;

    &:hover {
        border: 1px solid rgb(134, 240, 233);
        cursor: pointer;
        background-color: rgb(134, 240, 233);
    }
}
.nav-link{
    &:hover{
        color: white;
    }
}
.nav-home,.nav-category,.nav-about,.nav-contact,.nav-blog{
    border: 1px solid rgb(134, 240, 233);
    background-color: rgb(134, 240, 233) ;
}

.nav-login {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    img {
        inline-size: 30px;
        block-size: 30px;
        object-fit: cover;
        border-radius: 50%;
        cursor: pointer;
    }

    a {
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;

        &:hover {
            color: blue;
            cursor: pointer;
        }
    }
}
.nav-chat-mobile{
    display: flex;
    align-items: center;
}
.nav-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline-end: 50px;

    img {
        inline-size: 38px;
        block-size: 38px;
        object-fit: cover;
        border-radius: 50%;
    }

    a {
        font-size: 16px;
    }
}
#mobile{
    display: none;
}


#nav.active {
    top: 0;
    left: 0;
    width: 350px;
    height: 85vh;
    box-shadow: 0px 40px 60px rgba(0, 0, 0, 1);
    backdrop-filter: blur(10px);
    padding: 25px;
    position: fixed;
    z-index: 999;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
#nav.active .nav-right{
    display: flex;
    flex-direction: column;
}
#nav.active .nav-ul {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 40px;
    margin-bottom: 100px;
}

#nav.active .nav-ul .nav-li {
    border: none;
}
#nav.active .nav-login img{
    width: 40px;
    height: 40px;
}
#nav.active .nav-login a{
    font-size: 25px;
    font-weight: 600;
}
#nav.active #mobile {
    display: block;
}

#nav.active #mobile i {
    font-size: 18px;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    #nav {
        padding: 35px;
    }
    .nav-left-img {
        left: 50px;
        inline-size: 57px;
        block-size: 57px;
    }
    .nav-h3{
        font-size: 30px;
    }
    .nav-right {
        gap: 30px;
    }
    
    .nav-ul {
        gap: 5px;
    }
    
    .nav-li {
        font-size: 14px;
        font-weight: 500;
    }
    
    .nav-login {   
        img {
            inline-size: 25px;
            block-size: 25px;
        }
        a {
            font-size: 17px;
            font-weight: 500;
        }
    }
    .nav-chat {
        padding-inline-end: 10px;
    
        img {
            inline-size: 35px;
            block-size: 35px;
        }
    }       
}
@media screen and (max-width: 768px) {
    #nav {
        padding:30px 60px;
    }
    .nav-left-img {
        left: 48px;
        inline-size: 60px;
        block-size: 60px;
    }
    .nav-h3{
        font-size: 30px;
    }
    
    .nav-ul {
        display: none;
    }
    
    .nav-login {
        display: none;
    }
    .nav-chat-mobile{
        gap: 60px;
    }
    .nav-chat {
        padding-inline-end: 5px;
    
        img {
            inline-size: 40px;
            block-size: 40px;
        }
    
        a {
            font-size: 18px;
        }
    }
    #mobile{
        display: block;
    }
    #mobile i{
        font-size: 38px;
    }
    
    
    #nav.active {
        top: 0;
        left: 0;
        width: 640px;
        position: relative;
    }

    #nav.active .nav-ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 40px;
    }
    
    #nav.active .nav-ul .nav-li {
        font-size: 25px;
        font-weight: 500;
    }

    #nav.active .nav-login{
        display: flex;
    }
    #nav.active .nav-chat{
        display: none;
    }
    
    #nav.active #mobile {
        position: absolute;
        display: block;
        top: 30px;
        right: 50px;
    }
    
    #nav.active #mobile i {
        font-size: 40px;
        cursor: pointer;
    }
}
@media screen and (max-width: 412px) {
    #nav {
        padding:23px 30px;
    }
    .nav-left-img {
        left: 37px;
        inline-size: 45px;
        block-size: 45px;
    }
    .nav-h3{
        font-size: 23px;
    }
    .nav-right {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }
    
    .nav-ul {
        display: none;
    }
    
    .nav-login {
        display: none;
    }
    .nav-chat-mobile{
        gap: 30px;
    }
    .nav-chat {
        padding-inline-end: 5px;
    
        img {
            inline-size: 33px;
            block-size: 33px;
        }
    
        a {
            font-size: 16px;
        }
    }
    #mobile{
        display: block;
    }
    #mobile i{
        font-size: 32px;
    }
    
    
    #nav.active {
        top: 0;
        left: 0;
        width: 320px;
        position: relative;
    }
    #nav.active .nav-ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 40px;
    }
    
    #nav.active .nav-ul .nav-li {
        font-size: 18px;
        font-weight: 500;
    }

    #nav.active .nav-login{
        display: flex;
    }
    #nav.active .nav-chat{
        display: none;
    }
    
    #nav.active #mobile {
        position: absolute;
        display: block;
        top: 20px;
        right: 40px;
    }
    
    #nav.active #mobile i {
        font-size: 32px;
        cursor: pointer;
    }
}