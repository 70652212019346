.home {
    display: flex;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 30px;
}
.first-section {
    display: flex;
    flex-direction: column;
    align-items: center;  
    gap: 30px;
}
.first-section-h2{
    font-size: 28px;
    text-align: center;
    width: 40%;
}
.first-section-background{
    width: 100%;
    height: 65vh;
    background: center/cover url('../../../assets/backgroundImg.jpg') no-repeat;
    position: relative; 
}
.first-section-img {
    inline-size: 100%;
    block-size: 65vh;
    opacity: 0.1;
}
.first-section-h3{
    position: absolute;
    font-size: 45px;
    color: rgb(248, 248, 248);
    top: 110px;
    left: 30%;
    width: 40%;
    text-align: center;
}
hr{
    height: 3px;
}
.image-section-img{
    width: 100%;
    height: 70vh;
    margin-bottom: 0px;
}

.second-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
    margin: 70px 0px;
}
.second-section-scroll{
    width: 100%;
}
.second-section-marquee-img{
    margin: 0px 30px ;
}
.second-section-img{
    width: 100%;
    height: 100px;
    opacity: 0.4;
    &:hover{
        opacity: 1;
    }
}

.third-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
}

.third-section-cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 90px;
}
.third-section-items{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 30px 5px;
    /* background-color: rgb(134, 240, 233); */
    background-color: rgb(244, 192, 205);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover{
        transform: scale(1.05);
    }
}
.third-section-img{
    width: 100px;
    height: 100px;
}

@media screen and (max-width:1024px) {
    .home {
        margin-top: 20px;
    }
    .first-section { 
        gap: 30px;
    }
    .first-section-h2{
        font-size:  24px;
        width: 50%;
    }
    .first-section-background{
        height: 55vh;
    }
    .first-section-img {
        block-size: 55vh;
    }
    .first-section-h3{
        font-size: 32px;
        top: 85px;
        left: 35%;
        width: 30%;
    }
    hr{
        height: 4px;
    }
    .image-section-img{
        height: 60vh;
    }
    
    .second-section{
        gap: 50px;
        margin: 70px 0px;
    }
    .second-section-h1{
        font-size: 26px;
        text-align: center;
    }
    .second-section-marquee-img{
        margin: 0px 20px ;
    }
    .second-section-img{
        height: 80px;
    }
    
    .third-section{
        gap: 80px;
    }
    .third-section-h1{
        font-size: 26px;
    }
    
    .third-section-cards{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 80px;
    }
    .third-section-items{
        gap: 10px;
        padding: 30px 10px;
    }
    .third-section-img{
        width: 80px;
        height: 100px;
    } 
    .third-section-p1{
        font-size: 20px;
    }   
}
@media screen and (max-width:768px) {
    .home {
        margin-top: 20px;
    }
    .first-section { 
        gap: 20px;
    }
    .first-section-h2{
        font-size: 22px;
        width: 60%;
    }
    .first-section-background{
        height: 30vh;
    }
    .first-section-img {
        block-size: 30vh;
    }
    .first-section-h3{
        font-size: 30px;
        top: 80px;
        left: 30%;
        width: 40%;
    }
    hr{
        height: 4px;
    }
    .image-section-img{
        height: 30vh;
    }
    
    .second-section{
        gap: 40px;
        margin: 70px 0px;
    }
    .second-section-h1{
        font-size: 22px;
        text-align: center;
    }
    .second-section-marquee-img{
        margin: 0px 20px ;
    }
    .second-section-img{
        height: 70px;
    }
    
    .third-section{
        gap: 60px;
    }
    .third-section-h1{
        font-size: 22px;
    }
    
    .third-section-cards{
        grid-template-columns: 1fr 1fr 1fr;
        gap: 60px;
    }
    .third-section-items{
        gap: 10px;
        padding: 40px 15px;
    }
    .third-section-img{
        width: 90px;
        height: 90px;
    } 
    .third-section-p1{
        font-size: 20px;
    }   
}
@media screen and (max-width:412px) {
    .home {
        margin-top: 10px;
    }
    .first-section { 
        gap: 10px;
    }
    .first-section-h2{
        font-size: 18px;
        width: 100%;
    }
    .first-section-background{
        height: 24vh;
    }
    .first-section-img {
        block-size: 24vh;
    }
    .first-section-h3{
        font-size: 26px;
        top: 50px;
        left: 10%;
        width: 80%;
    }
    hr{
        height: 3px;
    }
    .image-section-img{
        height: 25vh;
    }
    
    .second-section{
        gap: 20px;
        margin: 70px 0px;
    }
    .second-section-h1{
        font-size: 19px;
        text-align: center;
    }
    .second-section-marquee-img{
        margin: 0px 20px ;
    }
    .second-section-img{
        height: 60px;
    }
    
    .third-section{
        gap: 40px;
    }
    .third-section-h1{
        font-size: 20px;
    }
    
    .third-section-cards{
        grid-template-columns: 1fr 1fr;
        gap: 50px;
    }
    .third-section-items{
        gap: 10px;
        padding: 30px 5px;
    }
    .third-section-img{
        width: 60px;
        height: 70px;
    } 
    .third-section-p1{
        font-size: 17px;
    }   
}

