*{
  margin: 0;
  padding: 0;
}
hr{
  height: 1px;
  background-color: rgba(158, 161, 162, 0.854);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
