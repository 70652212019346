.category {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 140px;
    gap: 40px;

    .category-up,.category-down {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }
    .items {
        display: flex;
        flex-direction: column;          
    }
    .category-img {
        inline-size: 200px;
    } 
    .details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }
    .category-h3 {
        font-size: 20px;
        font-weight: 500;
    }
    .category-btn {
        inline-size: 50%;
        border: 1px solid blue;
        border-radius: 20px;
        color: blue;
        font-size: 18px;
        padding: 5px 0px;

        &:hover {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 1100px) {
    .category {
        padding: 40px 80px;
    }
    .category-up, .category-down {
        gap: 30px;
    }
    .details .category-h3 {
        font-size: 18px;
    }
    .details .category-btn {
        width: 60%;
    }
}

@media screen and (max-width: 768px) {
    .category {
        padding: 20px;
        gap: 20px;
    .category-up, .category-down {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 80px;
    }
    .category-down{
        margin-top: 50px;
    }
    .category-h3 {
        font-size: 16px;
    }
    .category-btn {
        width: 70%;
    }
}
}

@media screen and (max-width: 412px) {
    .category {
        padding: 20px;
        gap: 15px;
    .category-up, .category-down {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .category-img {
        width: 200px;
    }
    .category-h3 {
        font-size: 18px;
        text-align: center;
    }
    .category-btn {
        width: 80%;
    }
}
}
