.footer {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    gap: 50px;
}

.footer-hr {
    hr {
        height: 1px;
        background-color: lightgray;
    }
}

.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 30px;
    margin-bottom: 20px;
    gap: 120px;
}

.footer-list-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px;
}
.footer-ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
}

.footer-li {
    list-style: none;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;

    &:hover {
        color: green;
        cursor: pointer;
    }
}

.footer-social {
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer-social-img {
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.2);
        background-color: lightgray;
    }
}

.footer-contact {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.footer-contact1 {
    display: flex;
    align-items: center;
    border: 1px solid gray;
    width: 150px;
    border-radius: 30px;
    color: white;
    background-color: rgb(241, 104, 145);
    padding: 8px 20px;
    gap: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    &:hover{
        transform: scale(1.03);
    }
}
.footer-span1 {
    font-size: 25px;
    font-weight: 600;
}
.footer-p1{
    font-size: 35px;
    color: rgb(3, 139, 130);
}
.footer-span2{
    font-size: 25px;
    color: black;
}
.footer-contact1-img {
    width: 25px;
    height: 25px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footer-form1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

input, textarea, select {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    padding: 10px 0px;
}

select {
    color: rgb(223, 184, 31);
    border-bottom: 1px solid rgb(223, 184, 31);
}

.footer-form2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    
    label {
        width: 100%;
        margin-bottom: 3px;
    }
}

.footer-btn {
    border: none;
    margin: auto;
    font-size: 20px;
    font-weight: 600;
    background-color: white;
    transition: all 0.2 ease-in;
    width: 10%;
    color: rgb(189, 176, 32);

    &:hover {
        color: rgb(85, 87, 83);
    }
}

.footer-contact-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    width: 30%;
}

.footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-logo-heading{
    display: flex;
    align-items: center;
    position: relative;
}
.footer-logo-img {
    position: absolute;
    left: 53px;
    inline-size: 65px;
    block-size: 65px;
    object-fit: cover;
    border-radius: 50%;
    opacity: 0.8;
    z-index: 10;
}
.footer-logo-h3{
    font-size: 32px;
    font-weight: 800;
    z-index: 100;
}

.footer-h2 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.footer-p2 {
    font-size: 6px;
}

.footer-copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}

@media screen and (max-width:768px) {
    .footer {
        display: flex;
        flex-direction: column;
        margin-top: 200px;
        gap: 50px;
    }

    .footer-hr {
        hr {
            height: 3px;
        }
    }

    .footer-container {
        display: flex;
        flex-direction: column-reverse;
        margin: 0px 20px;
        margin-bottom: 20px;
        gap: 50px;
    }

    .footer-list-social {
        flex-direction: row;
        justify-content: space-between;
    }

    .footer-ul {
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
    }
    
    .footer-li{
        font-size: 15px;
    }
    .footer-social {
        flex-direction: column;
        gap: 20px;
    }
    .footer-social-img{
        width: 30px;
        height: 30px;
    }
    .footer-contact {
        gap: 30px;
        width: 70%;
    }
    .footer-contact1 {
        width: 100px;
        padding: 8px 15px;
    }
    .footer-span1 {
        font-size: 18px;
        font-weight: 500;
    }
    .footer-contact1-img {
        width: 18px;
        height: 18px;
    }
    form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 0px 10px;
    }
    .footer-form1 {
        display: block;
        width: 100%;
    }
    input, textarea, select {
        width: 100%;
        padding: 15px 0px;
    }
    .footer-form2 {
        width: 100%;
        gap: 5px;
        input{
            width: 4%;
        }
        label {
            width: 100%;
            font-size: 19px;
        }
    }
    .footer-contact-area {
        gap: 20px;
        width: 95%;
    }
    .footer-logo-img {
        left: 55px;
        inline-size: 65px;
        block-size: 65px;
    }
    .footer-logo-h3{
        font-size: 35px;
        font-weight: 800;
    }

    .footer-h2 {
        font-size: 18px;
        font-weight: 500;
    }

    .footer-p2 {
        font-size: 5px;
    }

    .footer-copyright {
        font-size: 10px;
    }
}
@media screen and (max-width:412px) {
    .footer {
        display: flex;
        flex-direction: column;
        margin-top: 200px;
        gap: 50px;
    }

    .footer-hr {
        hr {
            height: 3px;
        }
    }

    .footer-container {
        display: flex;
        flex-direction: column-reverse;
        margin: 0px 20px;
        margin-bottom: 20px;
        gap: 50px;
    }

    .footer-list-social {
        flex-direction: row;
        justify-content: space-between;
    }

    .footer-ul {
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
    }
    
    .footer-li{
        font-size: 14px;
    }
    .footer-social {
        flex-direction: column;
        gap: 20px;
    }
    .footer-social-img{
        width: 25px;
        height: 25px;
    }
    .footer-contact {
        gap: 30px;
        width: 100%;
    }
    .footer-contact1 {
        width: 100px;
        padding: 8px 15px;
    }
    .footer-span1 {
        font-size: 18px;
        font-weight: 500;
    }
    .footer-contact1-img {
        width: 18px;
        height: 18px;
    }
    form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 0px 10px;
    }
    .footer-form1 {
        display: block;
        width: 100%;
    }
    input, textarea, select {
        width: 100%;
        padding: 15px 0px;
    }
    .footer-form2 {
        width: 100%;
        gap: 5px;
        input{
            width: 5%;
        }
        label {
            width: 100%;
            font-size: 18px;
            margin-top: 5px;
            margin-bottom: 4px;
        }
    }
    .footer-contact-area {
        gap: 20px;
        width: 95%;
    }
    .footer-logo-img {
        left: 48px;
        inline-size: 60px;
        block-size: 60px;
    }
    .footer-logo-h3{
        font-size: 30px;
        font-weight: 800;
    }
    

    .footer-h2 {
        font-size: 18px;
        font-weight: 500;
    }

    .footer-p2 {
        font-size: 5px;
    }

    .footer-copyright {
        font-size: 10px;
    }
}