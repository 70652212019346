/* .contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    gap: 40px;
}
.contact-h3{
    font-size: 25px;
}
.contact-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.contact-p1{
    font-size: 20px;
}
form{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
}
input,textarea{
    padding: 20px 0px;
}
.contact-btn{
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    margin: auto;
    width: 20%;
    transition: all 0.3s ease-in-out;
    &:hover{
        color: gray;
    }
} */


.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    gap: 40px;
}

.contact-h3 {
    font-size: 25px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.contact-p1 {
    font-size: 20px;
}

form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
}

input,
textarea {
    padding: 20px 0px;
    width: 100%;
    box-sizing: border-box;
}

.contact-btn {
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    margin: auto;
    width: 20%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.contact-btn:hover {
    color: gray;
}

@media screen and (max-width: 1100px) {
    .contact {
        padding: 0 20px;
    }
    form {
        width: 80%;
    }
    .contact-btn {
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    .contact-h3 {
        font-size: 22px;
    }
    .contact-p1 {
        font-size: 18px;
    }
    form {
        width: 100%;
    }
    .contact-btn {
        width: 40%;
    }
}

@media screen and (max-width: 412px) {
    .contact-h3 {
        font-size: 20px;
        text-align: center;
    }
    .contact-p1 {
        font-size: 16px;
        text-align: center;
    }
    form {
        width: 90%;
    }
    input,
    textarea {
        padding: 15px 10px;
    }
    .contact-btn {
        width: 50%;
    }
}
